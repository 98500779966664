<template>
  <div>
    <div class="d-flex ">
      <b-col
        md="3"
      >
        <b-form-group
          label="Année universitaire"
        >
          <v-select
            v-model="academicYear"
            :clearable="false"
            label="academic_year"
            placeholder="Année universitaire"
            :options="academicYears"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Parcours"
        >
          <v-select
            v-model="parcour"
            :clearable="false"
            placeholder="parcour"
            label="title"
            :options="parcours"
            @input="getSubjects"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Unités"
        >
          <v-select
            v-model="unity"
            :clearable="false"
            placeholder="choisire unitée"
            label="label"
            :options="unites"
            @input="getSubjects"
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Semester"
        >
          <v-select
            v-model="semester"
            :clearable="false"
            placeholder="Semester"
            :options="semesters"
            @input="getSubjects"
          />

        </b-form-group>
      </b-col>

      <!-- <b-col md="3">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col> -->

    </div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Nom du Groupe : {{ groups.group_code }}
    </h4>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="subjects"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <b-form-input
                  v-model="props.row.group"
                  placeholder="Groupe Code"
                  value=" this.$route.params"
                  @change="setNotes(props.row.student.id, props.row.group.id, subjectIdd ,props.row.note, year, props.row.semester)"
                />
              </div>
            </div>

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              <b-button
                variant="primary"
                type="submit"
                class="d-flex align-items-center"
                @click.prevent="addNote"
              >
                Affecter les Matiéres a ce groupe
              </b-button>
            </span>

          </div>
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BCol,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BCol,
    vSelect,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      columns: [
        {
          label: 'Id',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Subject',
          field: 'label',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
      ],
      groups: [],
      parcours: [],
      parcour: '',
      unity: '',
      unites: [],
      subjects: [],
      semesters: ['semester 1', 'semester 2'],
      semester: '',
      academicYear: {},
      academicYears: [],
      currentAcademicYear: {},
      searchTerm: '',
      deliberationResults: [],

      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      statuss: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },
    notezero() {
      return [this.subjectes[0]]
      // this.testSubject()
      // return this.semesterNote
    },
    result() {
      return this.pushDeliberation()
    },

  },
  created() {
    this.getAcademicYears()
    this.getParcours()
    this.getTeachingUnits()
    this.getSubjects()
    this.getGroups()
    this.pushDeliberation()
    this.user = storeAuth.state.user
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
    this.groupe = this.$route.params.id
  },
  methods: {
    async pushDeliberation() {
      const newArr = this.subjects.map(subject => this.deliberationResults.push(subject.distributionsubjectsgroup_set))
      this.deliberationResults = newArr
      // console.log('checking subject in method newArr', newArr)
      // console.log('checking subject in method', this.subjects)
    },
    async getAcademicYears() {
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
    },
    async getParcours() {
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
    },
    async getTeachingUnits() {
      this.load = 'true'
      const response = await axios.get('/api/teaching-units/')
      this.unites = response.data
      // this.semaines = response.data
      this.load = 'false'
    },
    async getSubjects() {
      this.load = 'true'
      // const { id } = this.$route.params
      const response = await axios.get('/api/subjects/', {
        params: {
          academic_year_id: this.currentAcademicYear.id, unity__semester: this.semester, parcour_id: this.parcour.id, unity_id: this.unity.id,
        },
      })
      this.subjects = response.data
      // this.semaines = response.data
      this.load = 'false'
    },
    async getGroups() {
      this.load = 'true'
      const { id } = this.$route.params
      // console.log('id---', id)
      const response = await axios.get(`/api/groups/detail/${id}/`)
      this.groups = response.data
      // this.semaines = response.data
      this.load = 'false'
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
    deleteRoom(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/rooms/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getRooms()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Salle supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Salle Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
